<template>
  <div class="container" id="container">
    <!-- <div class="top-banner">
      <div class="banner-img">
        <img class="banner" src="../../../assets/images/jobs/banner.png" alt="">
      </div>
    </div> -->
    <div class="main-page">
      <div class="message-detail">
        <p class="content">{{ messageDetail.content }}</p>
        <p class="time">创建时间：{{ messageDetail.create_time }}</p>
      </div>
    </div>
  </div>

</template>

<script>

export default {
  name: "messageDetail",
  props: {
    langType: {
      default: 1
    },
    active: {}
  },
  data() {
    return {
      messageDetail: {}
    };
  },
  created() { },
  mounted() {
  },
  computed: {
  },
  watch: {
    '$route.query': {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue.id) {
          this.getDetail(newValue.id)
        }

      }
    },
  },
  methods: {
    getDetail(id) {
      this.$api
        .GET_MESSAGE_DETAIL({ id: id })
        .then((res) => {
          console.log(res)
          this.messageDetail = res
        })
    }

  },
};
</script>
<style lang="scss" scoped>
.container {
  height: calc(100vh - 50px);
  overflow-y: scroll;
  background: white;
  padding-bottom: 75px;
}

.top-banner {
  .banner-video {
    width: 100%;
    z-index: 10;
    overflow: hidden;

    .video {
      overflow: hidden;
      // border-radius: 15px 15px 0 0;
    }
  }

  .banner-img img {
    width: 100%;
  }
}

.job-list {
  padding-bottom: 25px;

  .event-cover{
    display:block;
    width:calc(100% - 40px);
    margin:0 20px;
  }



  ::v-deep .van-empty__image {
    width: 216px;
  }

  ::v-deep .van-empty__image img {
    width: 216px;
    height: 150px;
  }

  ::v-deep .van-empty__bottom {
    color: #999999;
    font-size: 14px;
  }

  ::v-deep .van-list__finished-text {
    color: #cccccc;
  }
}

.company-info {
  padding: 15px 19px;

  .top {
    display: flex;
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .name {
      position: relative;
      margin: 0;
      font-size: 18px;
      font-weight: bold;
      padding-left: 8px;

      &::before {
        display: block;
        content: "";
        width: 3px;
        height: 18px;
        background: #e95944;
        position: absolute;
        top: 4px;
        left: 0;
      }
    }

    span {
      display: flex;
      line-height: 24px;
    }
  }

  .title-more {
    display: flex;
    align-items: center;
    color: #333;
  }
}

::v-deep .van-popup__close-icon--top-right {
  top: 24px;
}

.btn {
  height: 38px;
  width: 100px;
  margin: 5px 20px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  border-radius: 25px;
  border: 1px solid #eaedf0;
  background: linear-gradient(90deg, #e95944 0%, #e95944 100%);
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
    opacity: .5;
  }
}

.confirm {
  color: #ffffff;
}

.job-result {
  .btn {
    margin: 0 auto;
    cursor: pointer;
  }
}

.detail-join {
  .btn {
    margin: 10px auto;
    cursor: pointer;
  }
}

::v-deep {
  .notice-list {
    .van-cell__title {
      text-align: left;
      font-size: 14px;
    }
  }
}

.my-sign {
  margin: 0 20px;
  display: flex;
  align-items: center;
  padding: 10px;
  flex-wrap: wrap;
  background: #f3f3f3;
  border-radius: 20px;
  font-size: 15px;
  position: relative;
  margin-bottom:10px;
  p {
    margin: 0 5px;
    width: 100%;
    text-align: left;
    margin-bottom: 5px;
  }

  .btn-box{
    display: flex;
      position: absolute;
      top: 5px;
      right: 5px;
    }

    .btn {
      width: 80px;
      height: 30px;
      margin: 0;
    }
}

.detail-join-fixed {
  width: 100%;
  background: #fff;
  position: fixed;
  bottom: 0px;
  left: 0px;
  text-align: center !important;
  z-index: 999;
  margin-bottom: 0 !important;
}

.btn-download {
  width: 120px;
  height: 40px;
  margin-top: 15px;
  color: #e95944;
  background: #ddd;
}

@media (min-width: 992px) {
  .container {
    height: 100%;
    padding-bottom: 75px;
    overflow-y: auto;

  }

  .main-page {
    max-width: 900px;
    margin: 0 auto;
  }

  .top-banner {
    .banner-video {
      width: 100%;
    }

    .banner-img img {
      width: 100%;
    }
  }

  .job-list {
    padding-bottom: 25px;

    .event-cover{
      display:block;
      width:200px;
      padding-left:20px;
      margin:0;
    }


    ::v-deep .van-empty__image {
      width: 216px;
    }

    ::v-deep .van-empty__image img {
      width: 216px;
      height: 150px;
    }

    ::v-deep .van-empty__bottom {
      color: #999999;
      font-size: 14px;
    }
  }

  .company-info {
    padding: 15px 19px;

    .title {
      .name {
        margin: 0;
        font-size: 18px;
        padding-left: 8px;

        &::before {
          display: block;
          content: "";
          width: 3px;
          height: 18px;
          top: 4px;
          left: 0;
        }
      }

      span {
        line-height: 24px;
      }
    }
  }

  ::v-deep .van-popup__close-icon--top-right {
    top: 24px;
  }

  .btn {
    height: 36px;
    width: 100px;
    font-size: 16px;
    border-radius: 25px;
    border: 1px solid #eaedf0;
    cursor: pointer;
  }

  .confirm {
    color: #ffffff;
    margin-left: auto;
  }

  .job-result {
    .btn {
      margin: 0 auto;
      cursor: pointer;
    }
  }

  .detail-join {
    .btn {
      margin: 10px auto;
      cursor: pointer;
    }
  }

  ::v-deep {
    .notice-list {
      .van-cell__title {
        text-align: left;
        font-size: 14px !important;
        padding-left: 10px;
      }
    }
  }

  .my-sign {
    margin: 0 20px;
    display: flex;
    align-items: center;
    padding: 10px;
    flex-wrap: wrap;
    background: #f3f3f3;
    border-radius: 20px;
    font-size: 15px;
    position: relative;
    margin-bottom:10px;
    p {
      width: 30%;
      margin: 0 5px;
      text-align: left;
      margin-bottom: 5px;
    }

    p:last-child {
      min-width: 100%;
    }

    .btn-box{
      display: flex;
      position: absolute;
      top: 5px;
      right: 5px;
    }

    .btn {
      width: 80px;
      height: 30px;
      margin: 0;
    }
  }

  .detail-join-fixed {
    width: 100%;
    background: #fff;
    position: fixed;
    bottom: 0px;
    left: 0px;
    text-align: center !important;
    z-index: 999;
    margin-bottom: 0 !important;
  }
}
</style>

<style scoped lang="scss">
.job-detail {
  padding-top: 15px;
}

.top-info {
  padding: 0 20px;
  align-self: flex-start;
  display: flex;
  justify-content: space-between;

  .title {
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    line-height: 33px;
    margin-right: 20px;
    text-align: left;
    padding-bottom: 0.5em;
  }

  .message-title{
    width: 30em;
    font-size:16px;
    white-space: nowrap; /* 不换行 */
    overflow: hidden; /* 超出部分隐藏 */
    text-overflow: ellipsis;
  }

  .go-detail {
    width: 60px;
    min-width: 60px;
    margin-right: 30px;
    transform: translateY(1.5px);
    text-decoration: underline;
    color: #e95944;
    font-size: 12px;
    margin-top: 7px;
  }
}

.labels {
  margin: 0px 20px;
  padding-bottom: 10px;
  display: flex;
  height: 20px;
  align-items: center;

  .label {
    font-size: 14px;
    color: #666666;
    display: flex;
    align-items: center;

    .icon-label {
      width: 15px;
      height: 15px;
      margin-right: 6px;
    }

    span {
      height: 20px;
      line-height: 20px;
    }

    span.work-city {
      max-width: 75px;
    }
  }


  .event-label{
    display:block;
  }

  .bar {
    width: 1px;
    height: 15px;
    margin: 0 10px;
    background: #f1f4f6;
  }
}

.scroll-div {
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .release-time {
    font-size: 12px;
    color: #999999;
    line-height: 17px;
    margin-bottom: 14px;
  }

  .card-info {
    padding: 6px 15px;
    background: #f8fafb;
    border-radius: 10px;
    margin-bottom: 25px;

    .van-col {
      padding: 5px;
      display: flex;
      align-items: center;
      font-size: 14px;

      .info-label {
        color: #999999;
      }

      .info-value {
        margin-left: 10px;
        font-weight: bold;
        color: #333333;
        max-width: 70px;
      }
    }
  }

  .sub-title {
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 0px;
    padding-top: 15px;
  }

  .detail {
    width: 100%;
    font-size: 15px;
    color: #666666;
    word-wrap: break-word;
    word-break: normal;
    white-space: pre-wrap;
    text-align: left;
    max-width: 100%;
    line-height: 28px;
    margin-bottom: 0px;

    p {
      margin: 0;
    }
  }
}

::v-deep .van-loading {
  width: 100%;
  height: 100%;
  background: white;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

.popup {
  overflow: hidden;

  .scroll-div {
    height: 354px;
    max-height: 48vh;
    overflow-y: scroll;
  }
}

::v-deep .detail p {
  margin-top: 10px;
  margin-bottom: 0;
}

.message-detail{
  text-align:left;
  padding:30px 0;
}

.message-detail .time{
  font-size:12px;
  color: #999;
}

@media (min-width: 992px) {
  .message-detail{
    padding:30px 0;
  text-align:left;
}

.message-detail .time{
  font-size:12px;
  color: #999;
}

  .job-detail {
    padding-top: 15px;
    border-bottom: 1px solid #efefef;
    display: flex;
    // align-items: center;
    // justify-content: space-between;
  }

  .top-info {
    padding: 0 20px;
    align-self: flex-start;
    display: flex;
    justify-content: space-between;

    .title {
      font-size: 18px;
      font-weight: bold;
      color: #333333;
      line-height: 33px;
      margin-right: 20px;
      text-align: left;
    }

    .message-title{
      width: 30em;
      font-size:16px;
      white-space: nowrap; /* 不换行 */
      overflow: hidden; /* 超出部分隐藏 */
      text-overflow: ellipsis;
    }

    .go-detail {
      width: 60px;
      min-width: 60px;
      margin-right: 30px;
      transform: translateY(1.5px);
      text-decoration: underline;
      color: #e95944;
      font-size: 12px;
      margin-top: 7px;
    }
  }

  .labels {
    margin: 0px 20px;
    padding-bottom: 10px;
    display: flex;
    height: 20px;
    align-items: center;
    position: absolute;
    bottom:0;

   

    .label {
      font-size: 14px;
      color: #666666;
      display: flex;
      align-items: center;

      .icon-label {
        width: 15px;
        height: 15px;
        margin-right: 6px;
      }

      span {
        height: 20px;
        line-height: 20px;
      }

      span.work-city {
        max-width: 75px;
      }
    }

    .event-label{
      display:block;
    }

    .bar {
      width: 1px;
      height: 15px;
      margin: 0 10px;
      background: #f1f4f6;
    }
  }

  .scroll-div {
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .release-time {
      font-size: 12px;
      color: #999999;
      line-height: 17px;
      margin-bottom: 14px;
    }

    .card-info {
      padding: 6px 15px;
      background: #f8fafb;
      border-radius: 10px;
      margin-bottom: 25px;

      .van-col {
        padding: 5px;
        display: flex;
        align-items: center;
        font-size: 14px;

        .info-label {
          color: #999999;
        }

        .info-value {
          margin-left: 10px;
          font-weight: bold;
          color: #333333;
          max-width: 70px;
        }
      }
    }

    .sub-title {
      font-size: 16px;
      font-weight: bold;
      color: #333333;
      margin-bottom: 0px;
      padding-top: 15px;
    }

    .detail {
      font-size: 15px;
      color: #666666;
      word-wrap: break-word;
      word-break: normal;
      white-space: pre-wrap;
      text-align: left;
      max-width: 100%;
      line-height: 28px;
      margin-bottom: 0px;

      p {
        margin: 0;
      }
    }
  }

  ::v-deep .van-loading {
    width: 100%;
    height: 100%;
    background: white;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
  }

  .popup {
    overflow: hidden;

    .scroll-div {
      height: 354px;
      max-height: 48vh;
      overflow-y: scroll;
    }
  }

  ::v-deep .detail p {
    margin-top: 10px;
    margin-bottom: 0;
  }

  .btn-download {
    width: 120px;
    height: 40px;
    margin-top: 15px;
    color: #e95944;
    background: #ddd;
  }
}
</style>
